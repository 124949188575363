export const _data = [
  {
    "file_path": "assets/demo/buscard/1.jpg",
    "prediction": {
      "company": [
        [
          1579,
          2454,
          1311,
          1396,
          "looloo technology co., ltd."
        ]
      ],
      "email": [
        [
          1806,
          2442,
          1106,
          1187,
          "knot@loolootech.com"
        ]
      ],
      "name_eng": [
        [
          1214,
          2449,
          499,
          622,
          "dr. thammanit pipatsrisawat"
        ]
      ],
      "name_th": [
        [
          1299,
          2448,
          624,
          756,
          "ดร. ธรรมนิติ์ พิพัฒน์ศรีสวัสดิ์"
        ]
      ],
      "phone": [
        [
          1979,
          2439,
          1014,
          1090,
          "+668-2709-2709"
        ]
      ],
      "position": [
        [
          2204,
          2440,
          786,
          862,
          "partner"
        ]
      ]
    }
  },
  {
    "file_path": "assets/demo/buscard/2.png",
    "prediction": {
      "company": [
        [
          378,
          628,
          217,
          237,
          "carenation (thailand) co.,ltd"
        ]
      ],
      "email": [],
      "name_eng": [
        [
          378,
          614,
          151,
          172,
          "parith rungsimanond"
        ]
      ],
      "name_th": [
        [
          380,
          566,
          122,
          150,
          "ปริชญ์ รังสิมานนท์"
        ]
      ],
      "phone": [
        [
          410,
          495,
          299,
          314,
          "66 3193371"
        ]
      ],
      "position": [
        [
          380,
          468,
          182,
          200,
          "co founder"
        ]
      ]
    }
  },
  {
    "file_path": "assets/demo/buscard/3.png",
    "prediction": {
      "company": [
        [
          496,
          616,
          218,
          238,
          "satisbuy co., ltd."
        ]
      ],
      "email": [
        [
          436,
          619,
          166,
          186,
          "supiti@proresidence.com"
        ]
      ],
      "name_eng": [
        [
          407,
          619,
          113,
          134,
          "supiti buranawatanachoke"
        ]
      ],
      "name_th": [],
      "phone": [
        [
          529,
          619,
          187,
          205,
          "080-250-2502"
        ]
      ],
      "position": [
        [
          486,
          617,
          135,
          155,
          "managing director"
        ]
      ]
    }
  },
  {
    "file_path": "assets/demo/buscard/5.png",
    "prediction": {
      "company": [
        [
          430,
          686,
          310,
          335,
          "looloo technology co., ltd."
        ]
      ],
      "email": [
        [
          460,
          679,
          252,
          274,
          "nonravee@loolootech.com"
        ]
      ],
      "name_eng": [
        [
          420,
          680,
          80,
          114,
          "nonravee benjapibal"
        ]
      ],
      "name_th": [
        [
          470,
          681,
          120,
          152,
          "นนรวีร์ เบญจาภิบาล"
        ]
      ],
      "phone": [
        [
          548,
          681,
          226,
          247,
          "+668-9459-9458"
        ]
      ],
      "position": [
        [
          417,
          682,
          159,
          185,
          "business development manager"
        ]
      ]
    }
  },
  {
    "file_path": "assets/demo/buscard/6.png",
    "prediction": {
      "company": [
        [
          398,
          620,
          286,
          311,
          "looloo technology co.,ltd."
        ]
      ],
      "email": [
        [
          175,
          376,
          316,
          339,
          "nattaporn@loolootech.com"
        ]
      ],
      "name_eng": [
        [
          146,
          542,
          96,
          130,
          "nattaporn thoonsaengngam"
        ]
      ],
      "name_th": [
        [
          141,
          303,
          144,
          172,
          "ณัฏฐพร ทูลแสงงาม"
        ]
      ],
      "phone": [
        [
          175,
          289,
          288,
          309,
          "089-444-8944"
        ]
      ],
      "position": [
        [
          143,
          415,
          177,
          204,
          "business development manager"
        ]
      ]
    }
  },
]