<template>
  <BaseTemplate>
    <template slot="header-content">
      <div class="col-12 description-section">
        <div class="title">License Plate Recognition (LPR)</div>
        <div class="description">
          Our license plate recognition model is trained specially for the Thai license plate and the specific perspective as defined.
        </div>
      </div>
    </template>
    <template slot="input-content">
      <ImageSelect 
        :listImages="demo_datas" 
        v-model="data_select" 
        class="mb-3"
        :results="results"
        :type="page_type"
        :upload_image="upload_image"
      />
      
      <div class="d-grid d-md-flex">
        <ButtonComponent class="demo-bg-danger w-100" icon="east" text="Run Extraction" @click.native="clickSubmit()"/>
      </div>
    </template>
    <template slot="output-content">
      <BaseResult :is_loading="is_loading" style="height: 653px;">
        <template slot="result-title">
          Result
        </template>
        <template slot="result-detail">
          <div class="row mb-3 align-items-center" v-for="(car,index) in results" :key="index">
            <div class="col-4">
              <canvas :ref="'canvas-'+index" class="img-fluid border bg-secondary" :style="{ 'border': '3px solid ' + car.color+ '!important' }"></canvas>
            </div>
            <div class="col-4 d-flex flex-column">
                <span class="fw-bold">ทะเบียนรถ</span>
                <span>{{ textIfNUll(car.license) }}</span>
              </div>
            <div class="col-4 d-flex flex-column">
              <span class="fw-bold">จังหวัด</span>
              <span>{{ textIfNUll(car.province) }}</span>
            </div>
          </div>
        </template>
      </BaseResult>
    </template>
  </BaseTemplate>
</template>

<script>
import BaseTemplate from '@/templates/BaseTemplate.vue';
import ImageSelect from '@/components/select/ImageSelect.vue';
import ButtonComponent from '@/components/button/ButtonComponent.vue';
import BaseResult from '@/components/result/BaseResult.vue';
import axios from "axios"

import { _data } from "@/store/_data_lpr.ts";
export default {
  components: { 
    BaseTemplate,
    ImageSelect, 
    BaseResult,
    ButtonComponent
  },
  name: 'DemoLprHome',
  data() {
    return {
      page_type: "demo",
      demo_datas: _data.map(x=>{ x.imagePath = require('@/'+x.file_path); x.is_select = false; return x; }),
      data_select: null,
      is_loading: false,
      results: [],
      upload_image: "",
      colors: ["ff0000","ff8700","ffd300","deff0a","a1ff0a","0aff99","0aefff","147df5","580aff","be0aff"]
    }
  },
  updated: function () {
    const self = this
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been re-rendered
      self.results.map((x,i)=>{
        const canvas = self.$refs["canvas-" + i]
        const context = canvas[0].getContext('2d')
        let img = new Image()
        img.src = self.upload_image
        // Loading Pictures
        img.onload = function(){
          if(img.complete){
              var hRatio = context.canvas.width  / (x.xmax-x.xmin);
              var vRatio =  context.canvas.height / (x.ymax-x.ymin);
              var ratio  = Math.min ( hRatio, vRatio );
              var centerShift_x = ( context.canvas.width - (x.xmax-x.xmin)*ratio ) / 2;
              var centerShift_y = ( context.canvas.height - (x.ymax-x.ymin)*ratio ) / 2;  
              context.clearRect(0,0,context.canvas.width, context.canvas.height);

              context.drawImage(img, 
                x.xmin,x.ymin, 
                (x.xmax-x.xmin), (x.ymax-x.ymin),
                centerShift_x,centerShift_y,
                (x.xmax-x.xmin)*ratio, (x.ymax-x.ymin)*ratio
              );
          }
        }
      })
    })
  },
  watch: {
    data_select: function(){
      this.results = [],
      this.upload_image = ""
    },
  },
  methods:{
    clickSubmit(){
      if(this.data_select !== null) {
        if(this.page_type === 'demo'){
          this.is_loading = true;
          const self = this;
          setTimeout(function(){
            self.is_loading = false;
            self.results = self.data_select.data.predictions
            self.results.map((x,i)=>{
              x.color = "#" + (self.colors[i] ?? self.colors[0])
            })
            // string image demo
            self.upload_image = self.data_select.data.imagePath
          }, 1200);
        }
        if(this.page_type === 'upload'){
          this.is_loading = true;

          let request = new XMLHttpRequest();
          const self = this;
          request.responseType = "blob";
          request.onload = function() {
            let formData = new FormData();
            formData.append("image_input", request.response);

            self.getResult(formData);
          }
          request.open("GET", self.data_select.data.imagePath);
          request.send();
        }
      }
    },
    clickUpload(){
      this.data_select = {
        data: {
          imagePath: URL.createObjectURL(this.$refs.file.files[0])
        },
        type: "upload"
      }
    },
    getResult(formData){
      const self = this;
      let config = {
        method: 'post',
        url: 'https://ourworks-api-lpr.loolootest.com/uploadfile/',
        headers: { 
          'Content-Type': 'multipart/form-data',
        },
        data : formData
      };
      axios(config)
        .then(function(res){
          self.results = res.data.predictions
          self.results.map((x,i)=>{
            x.color = "#" + (self.colors[i] ?? self.colors[0])
          })
          self.upload_image = self.data_select.data.imagePath
        })
        .catch(function(err){
          console.log(err);
        })
        .finally(()=>{
          self.is_loading = false;
        });
    },
    textIfNUll(item) {
      return !item? '-' : item;
    },
  },
}
</script>

<style>
