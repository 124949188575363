export const _data = [
  {
    "file_path": "assets/demo/loc/1.jpg",
    "result_path": "assets/demo/loc/result/1.jpg",
    "prediction": {
      "TO": [
        0,
        0,
        0,
        0,
        "KASIKORNBANK PUBLIC COMPANY LIMITED"
      ],
      "DATE": [
        0,
        0,
        0,
        0,
        "08-Mar-2022"
      ],
      "APPLICANT": [
        0,
        0,
        0,
        0,
        "PARICH FERTILIZER CO., LTD 1477/1 SOI PATTANAKARN 31/1, PATTANAKARN ROAD, SUANLUANG, BANGKOK 10250, THAILAND"
      ],
      "TEL": [
        0,
        0,
        0,
        0,
        "+66-2319-3317-3"
      ],
      "FAX": [
        0,
        0,
        0,
        0,
        "+66-2319-3374"
      ],
      "BENEFICIARY": [
        0,
        0,
        0,
        0,
        "Can Tho Fertilizer and Chemical Joint Stock Company Tra Noc 1 Industrial Park, Binh Thuy District Cantho City, Vietnam"
      ],
      "TEL2": [
        0,
        0,
        0,
        0,
        "+84 292 3842230"
      ],
      "FAX2": [
        0,
        0,
        0,
        0,
        "+84 292 3841429"
      ],
      "CREDIT AMOUNT": [
        0,
        0,
        0,
        0,
        "USD 400,000.00"
      ],
      "(+/-)": [
        0,
        0,
        0,
        0,
        "5 %"
      ]
    }
  },
  {
    "file_path": "assets/demo/loc/2.jpg",
    "result_path": "assets/demo/loc/result/2.jpg",
    "prediction": {
      "TO": [
        0,
        0,
        0,
        0,
        "KASIKORNBANK PUBLIC COMPANY LIMITED"
      ],
      "DATE": [
        0,
        0,
        0,
        0,
        "28-Mar-2022"
      ],
      "APPLICANT": [
        0,
        0,
        0,
        0,
        "PARICH FERTILIZER CO., LTD 1477/1 SOI PATTANAKARN 31/1, PATTANAKARN ROAD, SUANLUANG, BANGKOK 10250, THAILAND"
      ],
      "TEL": [
        0,
        0,
        0,
        0,
        "+66-2319-3317-3"
      ],
      "FAX": [
        0,
        0,
        0,
        0,
        "+66-2319-3374"
      ],
      "BENEFICIARY": [
        0,
        0,
        0,
        0,
        "VEDAN (VIETNAM) ENTERPRISE CORP., LTD NATIONAL ROAD 51, HAMLET 1A, PHUOC THAI VILLAGE, LONG THANH DISTRICT, DONG NAI PROVINCE, VIETNAM"
      ],
      "TEL2": [
        0,
        0,
        0,
        0,
        "+84-251-3825111"
      ],
      "FAX2": [
        0,
        0,
        0,
        0,
        "+84-251-3551-803"
      ],
      "CREDIT AMOUNT": [
        0,
        0,
        0,
        0,
        "USD 73,185.00"
      ],
      "(+/-)": [
        0,
        0,
        0,
        0,
        "-"
      ]
    }
  },
  {
    "file_path": "assets/demo/loc/3.jpg",
    "result_path": "assets/demo/loc/result/3.jpg",
    "prediction": {
      "TO": [
        0,
        0,
        0,
        0,
        "KASIKORNBANK PUBLIC COMPANY LIMITED"
      ],
      "DATE": [
        0,
        0,
        0,
        0,
        "14-Mar-2022"
      ],
      "APPLICANT": [
        0,
        0,
        0,
        0,
        "PARICH FERTILIZER CO., LTD 1477/1 SOI PATTANAKARN 31/1, PATTANAKARN ROAD, SUANLUANG, BANGKOK 10250, THAILAND"
      ],
      "TEL": [
        0,
        0,
        0,
        0,
        "+66-2319-3317-3"
      ],
      "FAX": [
        0,
        0,
        0,
        0,
        "+66-2319-3374"
      ],
      "BENEFICIARY": [
        0,
        0,
        0,
        0,
        "Can Tho Fertilizer and Chemical Joint Stock Company Tra Noc 1 Industrial Park, Binh Thuy District Cantho City, Vietnam"
      ],
      "TEL2": [
        0,
        0,
        0,
        0,
        "+84 292 3842230"
      ],
      "FAX2": [
        0,
        0,
        0,
        0,
        "+84 292 3841429"
      ],
      "CREDIT AMOUNT": [
        0,
        0,
        0,
        0,
        "USD 417,500.00"
      ],
      "(+/-)": [
        0,
        0,
        0,
        0,
        "5 %"
      ]
    }
  },
]