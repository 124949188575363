<template>
  <div class="hstack gap-1 submit-button">
    <button class="run-extraction-btn" v-on:click="runExtraction()">
      Run Extraction<img
        id="arrow-image"
        src="@/assets/htr-images/arrow-right.png"
      />
    </button>
  </div>
</template>

<script>
export default {
  name: 'NLPSubmit',
}
</script>

<style scoped>
.submit-button::v-deep {
  margin-top: 12px;
}

.submit-button::v-deep .run-extraction-btn {
  width: 100%;
  height: 80px;
  background: #FB7A1E;
  border-radius: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 135%;
  color: #ffffff;
  border: none;
}

.submit-button::v-deep #arrow-image {
  margin-left: 8px;
}

@media screen and (min-width: 375px) and (max-width: 600px) {
  .submit-button::v-deep .hstack {
    flex-wrap: wrap;
  }

  .submit-button::v-deep .run-extraction-btn {
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .submit-button::v-deep .run-extraction-btn {
    font-size: 2.34vw;
  }
}
</style>
