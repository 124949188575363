<template>
  <BaseTemplate>
    <template slot="header-content">
      <div class="col-12 description-section">
        <div class="title">Speech Recognition</div>
        <div class="description">
          Our Thai speech recognition software is an advanced technology that allows a program to fully recognize, understand and interpret Thai spoken input such as words or phrases and accurately convert them into precise text. NLP technology plays an essential role in speech recognition by simplifying the processes to minimize the amount of time it takes to accomplish.
        </div>
      </div>
    </template>
    <template slot="input-content">
      <div v-show="!isRecord">
        <StopSoundWave/>
        <div class="d-grid d-md-flex">
          <RecordButton 
            class="demo-bg-danger w-100" icon="mic" text="Speak" :isRecord="false"
            @click.native="clickSpeak('start')"/>
        </div>
      </div>
      <div v-show="isRecord">
        <SoundWave/>
        <div class="d-grid d-md-flex">
          <RecordButton 
            class="demo-bg-danger w-100" icon="stop" text="Stop" :isRecord="true"
            @click.native="clickSpeak('stop')"/>
        </div>
      </div>
    </template>
    <template slot="output-content">
      <BaseResult style="height: 653px;">
        <template slot="result-title">
          Result
        </template>
        <template slot="result-detail">
          <div class="text-center">
            
          </div>    
        </template>
      </BaseResult>
    </template>
  </BaseTemplate>
</template>

<script>
import BaseTemplate from '@/templates/BaseTemplate.vue';
import SoundWave from '@/components/loader/SoundWave.vue';
import BaseResult from '@/components/result/BaseResult.vue';
import RecordButton from '@/components/button/RecordButton.vue'
import StopSoundWave from '@/components/loader/StopSoundWave.vue'

export default{
    components:{
        BaseTemplate, SoundWave, BaseResult, RecordButton, StopSoundWave
    },
    name: 'SpeechHome',
    data() {
    return {
      isRecord:false,
    }
  },
  methods:{
    clickSpeak(state){

      if(state === 'start'){
        this.isRecord = true;
      }else{
        this.isRecord = false;
      }
    },
  },
}
</script>

<style scoped>
</style>