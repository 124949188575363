export const _data = [
  {
    "file_path": "assets/demo/carbook/1.png",
    "prediction": {
        "body_code": [
          0,
          0,
          0,
          0,
          "MR053BK3005018952"
        ],
        "engine_code": [
          0,
          0,
          0,
          0,
          "2AZ3081663"
        ]
      }
  },
  {
    "file_path": "assets/demo/carbook/2.png",
    "prediction": {
        "body_code": [
          0,
          0,
          0,
          0,
          "MRHCM56504P400121"
        ],
        "engine_code": [
          0,
          0,
          0,
          0,
          "K24AAP00402"
        ]
      }
  },
  {
    "file_path": "assets/demo/carbook/3.png",
    "prediction": {
        "body_code": [
          0,
          0,
          0,
          0,
          "MR053HY9305406896"
        ],
        "engine_code": [
          0,
          0,
          0,
          0,
          "1NZ-Y750753"
        ]
      }
  },
  {
    "file_path": "assets/demo/carbook/4.png",
    "prediction": {
        "body_code": [
          0,
          0,
          0,
          0,
          "MR054HY9104104999"
        ],
        "engine_code": [
          0,
          0,
          0,
          0,
          "1NZ-Y845470"
        ]
      }
  },
  {
    "file_path": "assets/demo/carbook/5.png",
    "prediction": {
        "body_code": [
          0,
          0,
          0,
          0,
          "MMTJNKA40BD038365"
        ],
        "engine_code": [
          0,
          0,
          0,
          0,
          "4D560CCZ9332"
        ]
      }
  },
  {
    "file_path": "assets/demo/carbook/6.png",
    "prediction": {
        "body_code": [
          0,
          0,
          0,
          0,
          "MR2K33F3801209699"
        ],
        "engine_code": [
          0,
          0,
          0,
          0,
          "3NR5228445"
        ]
      }
  },
  {
    "file_path": "assets/demo/carbook/7.png",
    "prediction": {
        "body_code": [
          0,
          0,
          0,
          0,
          "MP1TFR86JEG038385"
        ],
        "engine_code": [
          0,
          0,
          0,
          0,
          "4JK1MF7951"
        ]
      }
  },
]