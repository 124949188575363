<template>
  <div class="box">
    <span v-if="!imageUpload" class="w-100 text-center" style="padding-top: 50%;padding-bottom: 50%;background: rgba(0, 0, 0, 0.05);border-radius: 24px;">No Result</span>
    <div class="image-load-container position-relative" ref="image" v-show="imageUpload" v-else>
      <img :src="imageUpload" class="img-fluid upload-image" v-show="!results" />
      <canvas ref="canvas" class="img-fluid upload-image" v-show="results"></canvas>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageComponent',
  props: {
    imageUpload: {
      type: String,
      default: null
    },
    results: {
      type: Array,
      default: null
    }
  },
  watch: {
    results: function(newValue){
      const canvas = this.$refs.canvas
      const context = canvas.getContext('2d')
      let img = new Image()
      img.src = this.imageUpload
      // Loading Pictures
      img.onload = function(){
          if(img.complete){
              // The lengths and widths of canvas are reset according to the image
              canvas.setAttribute("width",img.width)
              canvas.setAttribute("height",img.height)
              // Draw pictures
              context.drawImage(img,0,0,img.width,img.height)

              let max_size = (img.width > img.height) ? img.width: img.height;
              let ratio = Math.ceil(max_size / 410)

              for(let result of newValue){
                context.strokeStyle = result.color
                context.lineWidth = ratio;
                context.strokeRect(result.xmin, result.ymin, result.xmax-result.xmin, result.ymax-result.ymin)
              }
          }
      }
    }
  }
}
</script>

<style>
.box{
  mix-blend-mode: normal;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-container {
  /* width: 624px;
  height: 554px; */
  background: rgba(0, 0, 0, 0.05);
  mix-blend-mode: normal;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container span {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #7b7b7b;
}

img.upload-image {
  border-radius: 16px;
}

canvas.upload-image {
  border-radius: 16px;
}

/* .image-load-container {
  width: 640px;
  height: 337px;
} */
.boxImage {
  position:absolute;
  top:0px;
  left:0px;
  width:100px;
  height:100px;
  border:5px solid #ff3f3f;
  background-color:transparent
}
</style>