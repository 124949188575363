<template>
  <div class="d-flex flex-lg-column min-vh-100 justify-content-center align-items-center">
    <div class="container">
      <div class="row">
      <div class="col-12">
      <div class="card border-0">
        <div class="card-body" v-if="!result.text">
          <div class="mb-3">
            <textarea
              class="form-control ner-textbox"
              rows="15"
              v-model="form.textInput"
            ></textarea>
          </div>
          <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
            <div class="d-flex">
              <div class="m-3" v-for="type in typeOptions" :key="type.value">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    :id="'type_' + type.value"
                    :value="type.value"
                    v-model="form.typeInput"
                  />
                  <label class="form-check-label" :for="'type_' + type.value">
                    {{ type.name }}
                  </label>
                </div>
              </div>
            </div>
            <div class="flex-grow-1 mb-3 ">
              <div class="col-12 col-sm-6 offset-sm-6">
                <button type="button" class="btn btn-lg btn-primary w-100 btn-ner" :disabled="!form.textInput || this.loadingState" @click="clickPostData">
                  <span class="d-flex justify-content-center" v-if="!loadingState">
                    ถัดไป
                    <span class="align-self-center material-icons-outlined">arrow_forward</span>
                  </span>
                  <div
                    class="d-flex justify-content-center snippet my-2"
                    data-title=".dot-flashing"
                    v-else
                  >
                    <div class="stage">
                      <div class="dot-flashing"></div>
                    </div>
                  </div>
                </button>
              </div>
              
            </div>
          </div>
        </div>
        <div class="card-body" v-else>
          <p class="mb-3 p-3 border result-box">
            <span class="result-text" v-html="resultText"></span>
            <!-- {{ resultText }} -->
          </p>
          <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
            <div class="flex-grow-1 mb-3 ">
              <div class="col-12 col-sm-6 offset-sm-6">
                <button type="button" class="btn btn-lg btn-primary w-100 btn-ner" @click="clickBack">
                  <span class="d-flex justify-content-center">
                    <span class="align-self-center material-icons-outlined"> arrow_back </span>
                    ย้อนกลับ
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div class="row" v-if="result.filters.length > 0">
            <div class="col-12 mb-3">
              <div class="card">
                <div class="card-header d-flex">
                  <span class="flex-grow-1">Filters</span>
                  <span class="badge bg-primary mx-1" role="button" @click="selectFilterAll()">เลือกทั้งหมด</span>
                  <span class="badge bg-danger mx-1" role="button" @click="deSelectFilterAll()">ล้างทั้งหมด</span>
                </div>
                <div class="card-body">
                  <span class="filter-type fs-5 mx-1 mb-3" :class="'badge ' + checkColor(val.key)" v-for="(val,index) of result.filters" :key="index">
                    <span class="form-check">
                      <input class="form-check-input" type="checkbox" value="" :id="'checkboxFilter-' + index" v-model="val.isSelect">
                      <label class="form-check-label pt-1" :for="'checkboxFilter-' + index">
                        {{ val.key }}
                      </label>
                    </span>
                  </span>
                </div>
              </div>
            </div>              
          </div>
          <div class="row" v-if="result.groups.length > 0">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  Roles
                </div>
                <div class="card-body">
                  <div class="row" v-for="(groupValue, groupIndex) of result.groups" :key="groupIndex">
                    <div class="col-12 col-sm-2 mb-3">
                      {{ groupValue.key }}
                    </div>
                    <div class="col-12 col-sm-10">
                      <span class="filter-type fs-5 mx-1 mb-3" :class="'badge filter-type-' + groupValue.key" v-for="(val,index) of groupValue.values" :key="index">
                        {{ val }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>              
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    <!-- <pre>{{ $data }}</pre> -->
  </div>
</template>

<script>
// import { _data_ner, _data_summary } from '@/store/_data_ner.ts'
import axios from 'axios'
export default {
  name: 'NerHome',
  data() {
    return {
      form: {
        textInput: '',
        typeInput: 'ner',
      },
      typeOptions: [
        { name: 'คำเฉพาะ', value: 'ner', keyUsed: 'entity' },
        { name: 'หลักไวยากรณ์', value: 'summary', keyUsed: 'pos', keyGroup: "role" },
      ],
      loadingState: false,
      result: {
        text: "",
        tokens: [],
        type: "",
        filters: [],
        colors: [],
        groups: [],
      }
    }
  },
  computed: {
    resultText: function() {
      const self = this
      let type = self.typeOptions.find((x) => x.value === self.result.type)
      let runIdx = 0;
      let filterToken = self.result.tokens
        .filter(x=>(x[type.keyUsed] !== 'O'))
        .reduce((str, arr) => {

        // replace with key to string
        let index = self.result.text.substr(runIdx).indexOf(arr.token)
        if (index !== -1) {
          // if not in badge
          str += self.result.text.substr(runIdx, index)
          runIdx += index
          // TODO: Add badge here
          if( type.keyGroup && arr[type.keyGroup]){
            str += '<span class="mx-1 badge ' + self.checkColor(arr[type.keyGroup]) + '">'
            + '<span class="d-inline-flex">'
            + '<span class="align-self-center">'
            + self.result.text.substr(runIdx, arr.token.length)
            + '</span>'
            + '<span class="align-self-center badge bg-white text-dark ms-1">'
            + arr[type.keyGroup]
            + '</span>'
            + '</span>'
            + '</span>'
          } else if(self.result.filters?.find(x=>x.key === arr[type.keyUsed])?.isSelect ?? false) {
            str += '<span class="mx-1 badge ' + self.checkColor(arr[type.keyUsed]) + '">'
            + '<span class="d-inline-flex">'
            + '<span class="align-self-center">'
            + self.result.text.substr(runIdx, arr.token.length)
            + '</span>'
            + '<span class="align-self-center badge bg-white text-dark ms-1">'
            + arr[type.keyUsed]
            + '</span>'
            + '</span>'
            + '</span>'
          } else {
            str += self.result.text.substr(runIdx, arr.token.length)
          }

          
        //   /////////
          runIdx += arr.token.length
          
        }
        return str
      }, '')

      if(runIdx < self.result.text.length){
        filterToken += self.result.text.substr(runIdx)
      }

      return filterToken
    }
  },
  methods: {
    selectFilterAll() {
      this.result.filters.map(x=>x.isSelect=true)
    },
    deSelectFilterAll() {
      this.result.filters.map(x=>x.isSelect=false)
    },
    clickPostData() {
      this.loadingState = true;
      // // // mock data
      // if (this.form.typeInput === 'ner') {
      //   this.result.text = _data_ner.input_text
      //   this.result.tokens = _data_ner.tokens
      //   this.result.type = _data_ner.type
      // } else {
      //   this.result.text = _data_summary.input_text
      //   this.result.tokens = _data_summary.tokens
      //   this.result.type = _data_summary.type
      // }
      // this.makeFilterAndGroup()
          

      // TODO: Make Axios
      let config = {
        method: 'post',
        url: 'https://police.loolootest.com/api/ner/' + this.form.typeInput,
        data : {
          text: this.form.textInput
        }
      };
      const self = this
      axios(config)
        .then(function(res){
          console.log(res);
          self.result.text = res.data.input_text ?? ""
          self.result.tokens = res.data.tokens ?? []
          self.result.type = res.data.type ?? ""
          self.makeFilterAndGroup()
        })
        .catch(function(){
          console.log('FAILURE!!');
        });
    },
    clickBack() {
      this.loadingState = false;
      this.result = {
        text: "",
        tokens: [],
        type: "",
        filters: [],
        colors: [],
        groups: [],
      }
    },
    checkColor(key){
      let index = this.result.colors.indexOf(key)
      return index === -1 || index>15 ? "filter-type-default" : "filter-type-" + index
    },
    makeFilterAndGroup(){
      const self = this
      let type = self.typeOptions.find((x) => x.value === self.result.type)
      // let filterToken = 
      self.result.tokens
        .filter(x=>(x[type.keyUsed] !== 'O'))
        .map(x=>{
          // color and filter
          if(x[type.keyUsed] && self.result.colors.indexOf(x[type.keyUsed]) === -1){
            self.result.colors.push(x[type.keyUsed])
            self.result.filters.push({
              key: x[type.keyUsed],
              isSelect: true
            })
          }

          // group
          if( type.keyGroup && type.keyGroup !== ""){
            if(x[type.keyGroup]){
              let keyIndex = self.result.groups.findIndex(y=>y.key===x[type.keyGroup]);
              if(keyIndex === -1){
                keyIndex = self.result.groups.length
                self.result.groups.push({ key: x[type.keyGroup], values:[]})
              }
              if(self.result.groups[keyIndex].values.indexOf(x.token) === -1){
                self.result.groups[keyIndex].values.push(x.token)
              }
            }
            
          }
          return x
        })

      self.result.colors = [...self.result.colors , ...self.result.groups.map(x=>x.key)]
    },
  },
}
</script>

<style>
/* .ner-container {
  margin: 205px auto;
  width: 1200px;
  height: 615px;
} */

.ner-textbox {
  /* width: 1200px;
  height: 500px; */
  background: #fdfefe;
  box-shadow: 0px 0px 50px 10px rgb(0 0 0 / 5%);
  border-radius: 12px;
  /* resize: none; */
}

/* button.btn-ner {
  width: 368px;
  height: 80px;
  background: #2196f3;
  box-shadow: 2px 2px 10px rgb(0 0 0 / 10%);
  border-radius: 12px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.btn-ner {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 150%;
  color: #fdfefe;
} */

/* button.btn-ner .material-icons-outlined {
  margin-left: 24px;
}

button.btn-ner .material-icons-outlined.back {
  margin-left: 0;
  margin-right: 24px;
} */

/* .ner-container .form-check-input:checked {
  background-color: #2196f3;
  border-color: #2196f3;
}

.ner-container .form-check {
  display: flex;
  align-items: center;
}

.ner-container label.form-check-label {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  color: #616161;
  margin-left: 18px;
} */

.filter-type {
  /* width: auto;
  height: 41px;
  padding: 3px 16px;
  border-radius: 12px; */
  background: #64b5f6;
  /* margin: 0 10px;
  white-space: nowrap;
  display: inline-flex;
  align-items: center; */
}
.filter-type-B-PERSON {
  background: #81c784;
}

.filter-type-I-PERSON {
  background: #64b5f6;
}

.filter-type-B-TIME {
  background: #ef9a9a;
}

.filter-type-I-TIME {
  background: #ffb74d;
}

span.filter-key {
  background: #fdfefe;
  border-radius: 4px;
  padding: 0 10px;
  margin-left: 10px;
  font-size: 10px;
  height: 20px;
  line-height: 2;
}

span.result-text {
  line-height: 30px;
}

.result-box {
  min-height: 500px;
  height: 500px;
  overflow-y: scroll;
}

span.filter-type-victim {
  background: #ffb74d;
}

span.filter-type-subject {
  background: #f48fb1;
}

span.filter-type-charge {
  background: #81c784;
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #bdbdbd;
  color: #bdbdbd;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #bdbdbd;
  color: #bdbdbd;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #bdbdbd;
  color: #bdbdbd;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

.filter-type-default {
  background: #f48fb1;
}

.filter-type-0 {
  background: #ffb74d;
}

.filter-type-1 {
  background: #f48fb1;
}

.filter-type-2 {
  background: #81c784;
}

.filter-type-3 {
  background: #64b5f6;
}

.filter-type-4 {
  background: #ffb74d;
}

.filter-type-5 {
  background: #f48fb1;
}

.filter-type-6 {
  background: #81c784;
}

.filter-type-7 {
  background: #64b5f6;
}

.filter-type-8 {
  background: #ffb74d;
}

.filter-type-9 {
  background: #f48fb1;
}

.filter-type-10 {
  background: #81c784;
}

.filter-type-11 {
  background: #64b5f6;
}

.filter-type-12 {
  background: #ffb74d;
}

.filter-type-13 {
  background: #f48fb1;
}

.filter-type-14 {
  background: #81c784;
}

.filter-type-15 {
  background: #64b5f6;
}


@keyframes dotFlashing {
  0% {
    background-color: #bdbdbd;
  }
  50%,
  100% {
    background-color: #fff;
  }
}
</style>