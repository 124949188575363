<template>
  <div class="image-select">
    <div class="no-image ratio ratio-16x9" v-if="!value || !value.data">Select Image</div>
    <div class="ratio ratio-16x9" v-if="value && value.data">
      <img :src="value.data.imagePath" class="d-block display-pic" alt="..." v-if="upload_image===''">
      <canvas ref="canvas" class="d-block display-pic" v-if="upload_image!==''"></canvas>
      <!-- <img :src="demo_image" class="d-block display-pic" alt="..." v-if="demo_image!==''"> -->
    </div>
    <div class="thumbnail-slider" id="slider">
      <div class="thumb">
        <div class="thumbnail-group" v-for="list,index in list_demo" :key="index" v-on:click="selectedImage(index)">
          <img :src="list.imagePath" class="img-fluid" alt="..." :class="{'current-selected': list.is_select}">
          <span class="material-icons checked-image" v-if="list.is_select">
            check_circle
          </span>

        </div>
        <!-- <div class="thumbnail-group" v-else>
          <img src="@/assets/htr-images/thumbnail_image_1.png" class="img-fluid current-selected" alt="...">
          <img class="checked-image" src="@/assets/htr-images/checked.png">
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageSelect",
  props: {
    value: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: 'demo'
    },
    listImages: Array,
    results: Array,
    upload_image: {
      type: String,
      default: ""
    },
    demo_image: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      list_demo: this.listImages,
    }
  },
  updated: function () {
    const self = this
    this.$nextTick(function () {

      const canvas = self.$refs.canvas
      if(canvas){
        const context = canvas.getContext('2d')
        let img = new Image()
        img.src = self.upload_image
        // Loading Pictures
        img.onload = function(){
          if(img.complete){
            // The lengths and widths of canvas are reset according to the image
            canvas.setAttribute("width",img.width)
            canvas.setAttribute("height",img.height)
            // Draw pictures
            context.drawImage(img,0,0,img.width,img.height)

            let max_size = (img.width > img.height) ? img.width: img.height;
            let ratio = Math.ceil(max_size / 410)

            for(let result of self.results){
              context.strokeStyle = result.color
              context.lineWidth = ratio;
              context.strokeRect(result.xmin, result.ymin, result.xmax-result.xmin, result.ymax-result.ymin)
            }
          }
        }
      }
      
    })
  },
  watch: {
    value: function(newValue){
      if(newValue.type === 'upload'){
        this.list_demo.map(x=>{ x.is_select = false; return x;});
      }
    }
  },
  methods: {
    selectedImage(index){
      this.list_demo.map(x=>{ x.is_select = false; return x;});
      this.list_demo[index].is_select = true;
      this.updateValue({
        data: this.list_demo[index],
        type: "demo"
      })
    },
    updateValue(data){
      this.$emit('input',data)
    },
  }
}
</script>

<style scoped>
  .image-select::v-deep .thumbnail-slider{
    position: relative;
    width: 100%;
    overflow-x: auto;
  }

  .image-select::v-deep .thumb{
  margin:20px 22px 20px 0;
  display: flex;
  }

  .image-select::v-deep .thumbnail-group{
    position:relative;
    margin-right: 22px;
  }

  .image-select::v-deep .checked-image{
    position: absolute;
    right: 10px;
    bottom: 9px;
    color: #FB7A1E; 
    background-color:white;
    border-radius: 50%;
  }
  .image-select::v-deep .no-image{
    background: #ffffff;
    border-radius: 12px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .image-select::v-deep .current-selected{
    border: 4px solid #FB7A1E;
    box-sizing: border-box;
    border-radius: 20px;
    opacity:1 !important;
  }

  .image-select::v-deep .img-fluid{
    width: 165.31px;
    height: 165.31px;
    border-radius: 20px;
    max-width:none;
    object-fit: cover;
    opacity:0.2;
  }

  .image-select::v-deep .display-pic{
    border-radius: 12px;
  }

  /* width */
  .image-select::v-deep #slider::-webkit-scrollbar {
    width: 8px;
    height:8px;
  }
  /* Track */
  .image-select::v-deep #slider::-webkit-scrollbar-track {
    box-shadow: inset 10px 10px 10px rgb(224, 224, 224, 0.5); 
    border-radius: 10px;
  }
  
  /* Handle */
  .image-select::v-deep #slider::-webkit-scrollbar-thumb {
    background: #FB7A1E;
    border-radius: 10px;
  }

  /* Handle on hover */
  .image-select::v-deep #slider::-webkit-scrollbar-thumb:hover {
    background: #FB7A1E; 
  }
</style>