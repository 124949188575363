export const _data = [
  {
    "file_path": "assets/demo/lpr/1.jpg",
    "predictions": [
      {
        "license": "1ขถ 9003",
        "province": "กรุงเทพมหานคร",
        "xmax": 2774,
        "xmin": 2613,
        "ymax": 1806,
        "ymin": 1743
      },
      {
        "license": "4กข 2068",
        "province": "กรุงเทพมหานคร",
        "xmax": 773,
        "xmin": 642,
        "ymax": 1156,
        "ymin": 1106
      }
    ]
  },
  {
    "file_path": "assets/demo/lpr/2.jpg",
    "predictions": [
      {
        "license": "2ฒค 6073",
        "province": "กรุงเทพมหานคร",
        "xmax": 3572,
        "xmin": 3454,
        "ymax": 932,
        "ymin": 884
      },
      {
        "license": "กน 1426",
        "province": "นครราชสีมา",
        "xmax": 2095,
        "xmin": 1979,
        "ymax": 993,
        "ymin": 947
      },
      {
        "license": "กจ 3802",
        "province": "",
        "xmax": 1188,
        "xmin": 1107,
        "ymax": 260,
        "ymin": 227
      }
    ]
  },
  {
    "file_path": "assets/demo/lpr/3.jpg",
    "predictions": [
      {
        "license": "7กญ 3112",
        "province": "กรุงเทพมหานคร",
        "xmax": 836,
        "xmin": 718,
        "ymax": 961,
        "ymin": 915
      },
      {
        "license": "89-2332",
        "province": "นครปฐม",
        "xmax": 3655,
        "xmin": 3495,
        "ymax": 909,
        "ymin": 831
      },
      {
        "license": "ผฉ 2431",
        "province": "อุบลราชธานี",
        "xmax": 2004,
        "xmin": 1898,
        "ymax": 740,
        "ymin": 696
      }
    ]
  },
  {
    "file_path": "assets/demo/lpr/4.jpg",
    "predictions": [
      {
        "license": "10-2600",
        "province": "",
        "xmax": 2428,
        "xmin": 2271,
        "ymax": 1088,
        "ymin": 1013
      },
      {
        "license": "9กม 8502",
        "province": "กรุงเทพมหานคร",
        "xmax": 804,
        "xmin": 688,
        "ymax": 944,
        "ymin": 897
      }
    ]
  },
  {
    "file_path": "assets/demo/lpr/5.jpg",
    "predictions": [
      {
        "license": "กม 2964",
        "province": "",
        "xmax": 657,
        "xmin": 531,
        "ymax": 1181,
        "ymin": 1131
      }
    ]
  },
  {
    "file_path": "assets/demo/lpr/6.jpg",
    "predictions": [
      {
        "license": "82-1764",
        "province": "",
        "xmax": 3278,
        "xmin": 3161,
        "ymax": 451,
        "ymin": 397
      },
      {
        "license": "4กด 5450",
        "province": "กรุงเทพมหานคร",
        "xmax": 2027,
        "xmin": 1922,
        "ymax": 860,
        "ymin": 817
      }
    ]
  },
]