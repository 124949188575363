<template>
  <button class="btn-poc">
    <slot name="BtnIcon"></slot>
    <slot name="BtnText"></slot>
  </button>
</template>

<script>
export default {
  name: 'BtnPoc',
}
</script>

<style>
</style>