<template>
  <BaseTemplate>
    <template slot="header-content">
      <div class="col-12 description-section">
        <div class="title">
          Optical Character Recognition (OCR)
        </div>
        <div class="description">
          Our OCR technology can automatically scan great volumes of documents and accurately extract printed Thai characters as simple as free text or as complex as specific fields in financial documents. 
        </div>
      </div>
      <div class="col-12 d-flex menu-tab-group">
        <div class="buscard active-tab">Business Card</div>
        <router-link :to="{ path: '/demo/ocr/receipt' }" class="text-reset text-decoration-none">
          <div class="invoice">Receipt</div>
        </router-link>
        <router-link :to="{ path: '/demo/ocr/letterofcredit' }" class="text-reset text-decoration-none">
          <div class="loc">Letter of Credit</div>
        </router-link>
        <a role="button" class="text-decoration-none text-muted">
          <div class="carbook">Carbook</div>
        </a>
      </div>
    </template>
    <template slot="input-content">
      <ImageSelect 
        :listImages="demo_datas" 
        v-model="data_select" 
        class="mb-3"
        :results="results"
        :type="page_type"
        :upload_image="upload_image"
      />
      
      <div class="d-grid d-md-flex">
        <ButtonComponent 
          class="demo-bg-danger w-100" icon="east" text="Run Extraction" 
          :class="{'disabled': !data_select}"
          @click.native="clickSubmit()"/>
      </div>
    </template>
    <template slot="output-content">
      <BaseResult :is_loading="is_loading" style="height: 653px;">
        <template slot="result-title">
          Result
        </template>
        <template slot="result-detail" v-if="results.length > 0">
          <dl class="row">
            <template v-for="(key,index) in mapping_keys" >
              <dt class="col-sm-3" :key="'dt'+index">{{ key.title }}</dt>
              <dd class="col-sm-9 text-truncate" :key="'dd'+index">
                <template v-if="results_raw[key.key].length > 0">
                  <p v-for="(buscard,index1) in results_raw[key.key]" :key="index1" >
                    {{ buscard[4] }}
                  </p>
                </template>
                <template v-else>
                  <p>-</p>
                </template>
              </dd>
            </template>
          </dl>                   
        </template>
      </BaseResult>
    </template>
  </BaseTemplate>
</template>

<script>
import BaseTemplate from '@/templates/BaseTemplate.vue';
import ImageSelect from '@/components/select/ImageSelect.vue';
import ButtonComponent from '@/components/button/ButtonComponent.vue';
import BaseResult from '@/components/result/BaseResult.vue';
import axios from "axios"

import { _data } from "@/store/_data_buscard.ts";
export default {
  components: { 
    BaseTemplate,
    ImageSelect, 
    BaseResult,
    ButtonComponent
  },
  name: 'DemoBuscardHome',
  data() {
    return {
      page_type: "demo",
      demo_datas: _data.map(x=>{ x.imagePath = require('@/'+x.file_path); x.is_select = false; return x; }),
      data_select: null,
      is_loading: false,
      results: [],
      results_raw: {},
      upload_image: "",
      colors: ["ff0000","ff8700","ffd300","deff0a","a1ff0a","0aff99","0aefff","147df5","580aff","be0aff"],
      mapping_keys: [
        { key: "company", title: "Company" },
        { key: "name_eng", title: "Name (ENG)" },
        { key: "name_th", title: "Name (TH)" },
        { key: "position", title: "Position" },
        { key: "phone", title: "Mobile" },
        { key: "email", title: "Email" },
      ],
    }
  },
  watch: {
    data_select: function(){
      this.results = [],
      this.upload_image = ""
      this.results_raw = {}
    },
  },
  methods:{
    clickSubmit(){
      if(this.data_select !== null) {
        if(this.page_type === 'demo'){
          this.is_loading = true;
          const self = this;
          setTimeout(function(){
            self.is_loading = false;
            self.results_raw = self.data_select.data.prediction
            self.results = self.renderPredictions(self.results_raw)
            self.results.map((x,i)=>{
              x.color = "#" + (self.colors[i] ?? self.colors[0])
            })
            // string image demo
            self.upload_image = self.data_select.data.imagePath
          }, 1200);
        }
        if(this.page_type === 'upload'){
          this.is_loading = true;

          let request = new XMLHttpRequest();
          const self = this;
          request.responseType = "blob";
          request.onload = function() {
            let formData = new FormData();
            formData.append("image_input", request.response);

            self.getResult(formData);
          }
          request.open("GET", self.data_select.data.imagePath);
          request.send();
        }
      }
    },
    clickUpload(){
      this.data_select = {
        data: {
          imagePath: URL.createObjectURL(this.$refs.file.files[0])
        },
        type: "upload"
      }
    },
    getResult(formData){
      const self = this;
      let config = {
        method: 'post',
        url: 'https://ourworks-api-buscard.loolootest.com/uploadfile/',
        headers: { 
          'Content-Type': 'multipart/form-data',
        },
        data : formData
      };
      axios(config)
        .then(function(res){
          self.results_raw = res.data
          self.results = self.renderPredictions(self.results_raw)
          self.results.map((x,i)=>{
            x.color = "#" + (self.colors[i] ?? self.colors[0])
          })
          self.upload_image = self.data_select.data.imagePath
        })
        .catch(function(err){
          self.results = [
            {
              recognize: "เกิดข้อผิดพลาด"
            }
          ]
          console.log(err);
        })
        .finally(()=>{
          self.is_loading = false;
        });
    },
    textIfNUll(item) {
      return !item? '-' : item;
    },
    renderPredictions(data){
      let predictions = []
      for(let key of Object.keys(data)){
        for(let val of data[key]){
          predictions.push({
          "type": key,
            "recognize": val[4],
            "xmin": val[0],
            "ymin": val[2],
            "xmax": val[1],
            "ymax": val[3],
          })
        }
      }
      return predictions;
    },
  },
}
</script>

<style>
