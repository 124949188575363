<template>
  <BaseTemplate>
    <template slot="header-content">
      <div class="col-12 description-section">
        <div class="title">Text Classification (NLP)</div>
        <div class="description">
          Our NLP can be utilized to classify text, especially in Thai language.
          For this tool, it can automatically recognize and analyze part of
          speech (noun and verb) of words in Thai sentences. It also can
          classify Thai words by their entities such as person's names, places,
          organizations, etc.
        </div>
      </div>
    </template>
    <template slot="input-content">
      <NLPInput></NLPInput>
      <NLPRadio></NLPRadio>
      <NLPSubmit></NLPSubmit>
    </template>
    <template slot="output-content">
      <NLPResult></NLPResult>
    </template>
  </BaseTemplate>
</template>

<script>
import BaseTemplate from '@/templates/BaseTemplate.vue';
import NLPInput from '@/components/input/NLPInput.vue';
import NLPRadio from '@/components/radio/NLPRadio.vue';
import NLPSubmit from '@/components/button/NLPSubmit.vue';
import NLPResult from '@/components/result/NLPResult.vue';

export default {
  components: { BaseTemplate, NLPInput, NLPSubmit, NLPResult, NLPRadio },
  name: 'DemoNlpHome',
  data() {
    return {
      inputText:'',
      outputText:'',
      outputType:'',
      selectedType:'',
      isRunExtraction: false,
      isActive: false,
      documentImage: [
        {
          imagePath: 'https://source.unsplash.com/vbNTwfO9we0/1600x900.jpg',
        },
        {
          imagePath: 'https://source.unsplash.com/DEhwkPYevhE/1600x900.jpg',
        },
        {
          imagePath: 'https://source.unsplash.com/-RV5PjUDq9U/1600x900.jpg',
        },
        {
          imagePath: 'https://source.unsplash.com/sd0rPap7Uus/1600x900.jpg',
        },
        {
          imagePath: 'https://source.unsplash.com/vbNTwfO9we0/1600x900.jpg',
        },
        {
          imagePath: 'https://source.unsplash.com/DEhwkPYevhE/1600x900.jpg',
        },
      ],
    }
  },
  methods: {


    selectedImage() {
      this.isActive = true
    },

    runExtraction() {
        this.isRunExtraction = true
        this.outputText = this.inputText
        this.outputType = this.selectedType
    },
  },
}
</script>

<style>

</style>
