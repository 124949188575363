<template>
  <BaseTemplate>
    <template slot="header-content">
      <div class="col-12 description-section">
        <div class="title">Handwritten Text Recognition (HTR)</div>
        <div class="description">
          Trained from more than 100,000 images, our latest version of Thai handwritten text recognition (HTR) system can effectively detect, read and transcribe Thai handwritten text appearing on envelopes into the digital text that is fully editable and readily searchable with exceptional accuracy.
        </div>
      </div>
    </template>
    <template slot="input-content">
      <ImageSelect 
        :listImages="demo_datas" 
        v-model="data_select" 
        class="mb-3"
        :results="results"
        :type="page_type"
        :upload_image="upload_image"
      />
      
      <div class="d-grid gap-2 d-md-flex">
        <input accept="image/*" type="file" ref="file" style="display: none" v-on:change="clickUpload()">
        <ButtonComponent class="demo-bg-primary" icon="photo_library" text="Upload" @click.native="$refs.file.click()"/>
        <ButtonComponent 
          class="demo-bg-danger w-100" icon="east" text="Run Extraction" 
          :class="{'disabled': !data_select}"
          @click.native="clickSubmit()"/>
      </div>
    </template>
    <template slot="output-content">
      <BaseResult :is_loading="is_loading" style="height: 653px;">
        <template slot="result-title">
          Result
        </template>
        <template slot="result-detail" v-if="results.length > 0">
          <div class="text-center">
            <p class="mb-3 h4" >found: {{ results.length }} {{ (results.length > 1 ? 'results' : 'result') }}</p>
            <p class="mb-3 h5" v-for="(htr,index) in results" :key="index">
              {{ htr.recognize }}
            </p>
          </div>
                   
        </template>
      </BaseResult>
    </template>
  </BaseTemplate>
</template>

<script>
import BaseTemplate from '@/templates/BaseTemplate.vue';
import ImageSelect from '@/components/select/ImageSelect.vue';
import ButtonComponent from '@/components/button/ButtonComponent.vue';
import BaseResult from '@/components/result/BaseResult.vue';
import axios from "axios"

import { _data } from "@/store/_data_htr.ts";
export default {
  components: { 
    BaseTemplate,
    ImageSelect, 
    BaseResult,
    ButtonComponent
  },
  name: 'HtrHome',
  data() {
    return {
      page_type: "upload",
      demo_datas: _data.map(x=>{ x.imagePath = require('@/'+x.file_path); x.is_select = false; return x; }),
      data_select: null,
      is_loading: false,
      results: [],
      upload_image: "",
      colors: ["ff0000","ff8700","ffd300","deff0a","a1ff0a","0aff99","0aefff","147df5","580aff","be0aff"]
    }
  },
  watch: {
    data_select: function(){
      this.results = [],
      this.upload_image = ""
    },
  },
  methods:{
    clickSubmit(){
      if(this.data_select !== null) {
        if(this.page_type === 'demo'){
          this.is_loading = true;
          const self = this;
          setTimeout(function(){
            self.is_loading = false;
            self.results = self.data_select.data.predictions
            self.results.map((x,i)=>{
              x.color = "#" + (self.colors[i] ?? self.colors[0])
            })
            // string image demo
            self.upload_image = self.data_select.data.imagePath
          }, 1200);
        }
        if(this.page_type === 'upload'){
          this.is_loading = true;

          let request = new XMLHttpRequest();
          const self = this;
          request.responseType = "blob";
          request.onload = function() {
            let formData = new FormData();
            formData.append("image_input", request.response);

            self.getResult(formData);
          }
          request.open("GET", self.data_select.data.imagePath);
          request.send();
        }
      }
    },
    clickUpload(){
      this.data_select = {
        data: {
          imagePath: URL.createObjectURL(this.$refs.file.files[0])
        },
        type: "upload"
      }
    },
    getResult(formData){
      const self = this;
      let config = {
        method: 'post',
        url: 'https://ourworks-api-htr.loolootest.com/uploadfile/',
        headers: { 
          'Content-Type': 'multipart/form-data',
        },
        data : formData
      };
      axios(config)
        .then(function(res){
          self.results = res.data.predictions
          self.results.map((x,i)=>{
            x.color = "#" + (self.colors[i] ?? self.colors[0])
          })
          self.upload_image = self.data_select.data.imagePath
        })
        .catch(function(err){
          self.results = [
            {
              recognize: "เกิดข้อผิดพลาด"
            }
          ]
          console.log(err);
        })
        .finally(()=>{
          self.is_loading = false;
        });
    },
    textIfNUll(item) {
      return !item? '-' : item;
    },
  },
}
</script>

<style>
