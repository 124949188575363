<template>
  <div class="d-flex">
    <div class="radio-item">
      <input
        type="radio"
        id="technical-term"
        name="radio"
        value="technical"
        v-model="selectedType"
      />
      <label for="technical-term">คำเฉพาะ</label>
    </div>
    <div class="radio-item">
      <input
        type="radio"
        id="grammar"
        name="radio"
        value="grammar"
        v-model="selectedType"
      />
      <label for="grammar">หลักไวยากรณ์</label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NLPRadio',
}
</script>

<style scoped>
.radio-item::v-deep {
  display: inline-block;
  position: relative;
}

.radio-item::v-deep input[type='radio'] {
  display: none;
}

.radio-item::v-deep label {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 135%;
  color: #FFFFFF;
  display: flex;
  margin: 15px 41px 0 33px;
}

.radio-item::v-deep label:before {
  content: ' ';
  display: inline-block;
  position: relative;
  top: 5px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 2px solid #FB7A1E;
  background-color: transparent;
  margin: 0 18px 0 0;
}

.radio-item::v-deep input[type='radio']:checked + label:after {
  border-radius: 20px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 24px;
  left: 37px;
  content: ' ';
  display: block;
  background: #FB7A1E;
}

@media screen and (min-width: 375px) and (max-width: 600px) {
  .radio-item::v-deep label {
    font-style: normal;
    font-weight: 700;
    font-size: 6.4vw;
    line-height: 135%;
    color: #FFFFFF;
    display: flex;
    margin: 4vw 0 0 2.667vw;
  }

  .radio-item::v-deep label:before {
    content: ' ';
    display: inline-block;
    position: relative;
    top: 1.333vw;
    width: 5.333vw;
    height: 5.333vw;
    border-radius: 5.333vw;
    border: 2px solid #FB7A1E;
    background-color: transparent;
    margin: 0 4.8vw 0 0;
  }

  .radio-item::v-deep input[type='radio']:checked + label:after {
    border-radius: 20px;
    width: 3.2vw;
    height: 3.2vw;
    position: absolute;
    top: 6.4vw;
    left: 3.7vw;
    content: ' ';
    display: block;
    background: #FB7A1E;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .radio-item::v-deep label {
    font-size: 2.3vw;
    margin: 1.95vw auto 0 1.2vw;
  }

  .radio-item::v-deep label:before {
    top: 0.26vw;
    width: 2.6041666666667vw;
    height: 2.6041666666667vw;
  }

  .radio-item::v-deep input[type='radio']:checked + label:after {
    top: 2.73vw;
    left: 1.69vw;
    width: 1.5625vw;
    height: 1.5625vw;
  }
}
</style>
