export const _data = [
  {
    "file_path": "assets/demo/invoice/1.png",
    "result_path": "assets/demo/invoice/result/1.png",
    "prediction": {
      "Issued by": [
        0,
        0,
        0,
        0,
        "CP ALL, 7-Eleven กรุงธนบุรี 6(10406)"
      ],
      "Invoice No.": [
        0,
        0,
        0,
        0,
        "R#0001118547P2:7529215"
      ],
      "List": [
        0,
        0,
        0,
        0,
        [
          ["1","แซนวิชเดนิชผักโขม","37.00"],
          ["1","ข้าวผัดคะน้าปลาเค็ม","49.00"],
          ["1","น้ำดื่มเพียวไลฟ์ 600","65.00"],
        ]
      ],
      "Date": [
        0,
        0,
        0,
        0,
        "28/04/65"
      ],
      "Time": [
        0,
        0,
        0,
        0,
        "11:41"
      ],
      "Total cost": [
        0,
        0,
        0,
        0,
        "151.00"
      ],
      "Payment": [
        0,
        0,
        0,
        0,
        "ทรูวอลเล็ท7App"
      ]
    }
  },
  {
    "file_path": "assets/demo/invoice/2.png",
    "result_path": "assets/demo/invoice/result/2.png",
    "prediction": {
      "Issued by": [
        0,
        0,
        0,
        0,
        "Sorry  i'm Hungry"
      ],
      "Invoice No.": [
        0,
        0,
        0,
        0,
        "T11002"
      ],
      "List": [
        0,
        0,
        0,
        0,
        [
          ["1","Hungry Burger SizeL(Pork)","179.00"],
          ["2","Cheese Burger M(Pork)","198.00"],
          ["2","Cheese Burger M(Beef)","218.00"],
          ["1","Double Cheese Burger","179.00"],
          ["1","Hungry Extra Cheese(Pork)","219.00"],
          ["2","Bacon Cheese Fries","238.00"],
          ["1","Hungry Rocket Berger(Beef)","319.00"],
        ]
      ],
      "Date": [
        0,
        0,
        0,
        0,
        "28-04-2565"
      ],
      "Time": [
        0,
        0,
        0,
        0,
        "17:47"
      ],
      "Total cost": [
        0,
        0,
        0,
        0,
        "1550.00"
      ],
      "Payment": [
        0,
        0,
        0,
        0,
        "-"
      ]
    }
  },
  {
    "file_path": "assets/demo/invoice/3.png",
    "result_path": "assets/demo/invoice/result/3.png",
    "prediction": {
      "Issued by": [
        0,
        0,
        0,
        0,
        "Day 1 วังหลัง"
      ],
      "Invoice No.": [
        0,
        0,
        0,
        0,
        "LGB/Y"
      ],
      "List": [
        0,
        0,
        0,
        0,
        [
          ["1","Iced Americano","75.00"],
        ]
      ],
      "Date": [
        0,
        0,
        0,
        0,
        "04/05/2022"
      ],
      "Time": [
        0,
        0,
        0,
        0,
        "16:08"
      ],
      "Total cost": [
        0,
        0,
        0,
        0,
        "75.00"
      ],
      "Payment": [
        0,
        0,
        0,
        0,
        "โอนเงิน"
      ]
    }
  },
  {
    "file_path": "assets/demo/invoice/4.png",
    "result_path": "assets/demo/invoice/result/4.png",
    "prediction": {
      "Issued by": [
        0,
        0,
        0,
        0,
        "Rolling Roasters พรานนก-พุธมณฑล บริษัท 999คิดบวก จำกัด"
      ],
      "Invoice No.": [
        0,
        0,
        0,
        0,
        "WSKTE"
      ],
      "List": [
        0,
        0,
        0,
        0,
        [
          ["1","Columbia Narino","180.00"],
          ["1","Iced Americano","130.00"],
        ]
      ],
      "Date": [
        0,
        0,
        0,
        0,
        "30/04/22"
      ],
      "Time": [
        0,
        0,
        0,
        0,
        "10:33"
      ],
      "Total cost": [
        0,
        0,
        0,
        0,
        "310.00"
      ],
      "Payment": [
        0,
        0,
        0,
        0,
        "VISA"
      ]
    }
  },
  {
    "file_path": "assets/demo/invoice/5.png",
    "result_path": "assets/demo/invoice/result/5.png",
    "prediction": {
      "Issued by": [
        0,
        0,
        0,
        0,
        "บริษัท ทนา เคอรี่ เฮาส์ จำกัด สาขาที่ 30 (TC26)"
      ],
      "Invoice No.": [
        0,
        0,
        0,
        0,
        "17253"
      ],
      "List": [
        0,
        0,
        0,
        0,
        [
          ["1","Pork Sliced Curry","165.00"],
          ["","-@Rice 150g","-20.00"],
          ["","- Spinach","30.00"],
          ["1","Miso Soup","25.00"],
        ]
      ],
      "Date": [
        0,
        0,
        0,
        0,
        "22/04/2022"
      ],
      "Time": [
        0,
        0,
        0,
        0,
        "17:43"
      ],
      "Total cost": [
        0,
        0,
        0,
        0,
        "200.00"
      ],
      "Payment": [
        0,
        0,
        0,
        0,
        "M/C CARD"
      ]
    }
  },
]