<template>
  <div class="d-flex flex-lg-column min-vh-100 justify-content-center align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 p-3" v-show="!isMobile() || imageUpload">
          <ImageComponent :imageUpload="imageUpload" :results="results"/>
        </div>
        <div class="col-12 col-md-6 p-3 d-flex align-items-center">
          <template v-if="!results">
            <div class="flex-fill" v-if="!uploadingImage">
              <BtnPoc @click.native="$refs.file.click()">
                <template v-slot:BtnIcon>
                  <img src="@/assets/images/upload-icon.png" class="btn-poc-icon" />
                </template>
                <template v-slot:BtnText>Upload</template>
              </BtnPoc>
              <BtnPoc data-bs-toggle="modal" data-bs-target="#libraryModal">
                <template v-slot:BtnIcon>
                  <img
                    src="@/assets/images/library-icon.png"
                    class="btn-poc-icon"
                  />
                </template>
                <template v-slot:BtnText>Library</template>
              </BtnPoc>
              <div class="modal fade" id="libraryModal">
                <div class="modal-dialog modal-lg">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">เลือกรูปภาพที่ต้องการ</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="libraryClose"></button>
                    </div>
                    <div class="modal-body">
                      <!-- @click.native="libraryClick()" -->
                      <div class="row">
                        <div class="col-12 col-md-4 mb-3" v-for="x of [1,2,3,4,5,6]" :key="x" role="button" >
                          <img :src="require('@/assets/demo-images/'+x+'.jpg')" class="img-thumbnail" @click="libraryClick(require('@/assets/demo-images/'+x+'.jpg'))">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <input accept="image/*" type="file" ref="file" style="display: none" v-on:change="clickUpload()">
            </div>
            <div class="flex-fill text-center" v-else>
              <PocLoading />
            </div>
          </template>
          <template v-else>
            <div class="flex-fill upload-complate">
              <div class="poc-back mb-3">
                <span role="button" @click="resetData">
                  <img src="@/assets/images/poc-back.png" class="poc-back-icon" />
                  Home
                </span>
              </div>
              <PocResult :imageUpload="imageUpload" :results="results"/>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageComponent from '@/components/ImageComponent'
import BtnPoc from '@/components/PocButton'
import PocLoading from '@/components/PocLoading'
import PocResult from '@/components/PocResult'
import axios from "axios"
// import { _data } from "@/store/_data_poc.ts"

export default {
  name: 'PocHome',
  data() {
    return {
      uploadingImage: false,
      results: null,
      imageUpload: null,
      colors: ["ff0000","ff8700","ffd300","deff0a","a1ff0a","0aff99","0aefff","147df5","580aff","be0aff"]
    }
  },
  components: {
    ImageComponent,
    BtnPoc,
    PocLoading,
    PocResult,
  },
  methods: {
    isMobile() {
      if( window.screen.width <= 760 ) {
        return true;
      }
      else {
        return false;
      }
    },
    getResult(formData){
      // formData
      // this.results = _data.predictions
      // this.results.map((x,i)=>{
      //   x.color = "#" + (this.colors[i] ?? this.colors[0])
      // })

      const self = this;
      let config = {
        method: 'post',
        url: 'https://ourworks-api-lpr.loolootest.com/uploadfile/',
        headers: { 
          'Content-Type': 'multipart/form-data',
        },
        data : formData
      };
      axios(config)
        .then(function(res){
          self.results = res.data.predictions
          self.results.map((x,i)=>{
            x.color = "#" + (self.colors[i] ?? self.colors[0])
          })
          // console.log(self.results);
        })
        .catch(function(err){
          console.log(err);
        });
    },
    libraryClick(path){
      this.$refs.libraryClose.click();
      this.uploadingImage = !this.uploadingImage
      this.imageUpload = window.location.origin + path;

      let request = new XMLHttpRequest();
      const self = this;
      request.responseType = "blob";
      request.onload = function() {
        let formData = new FormData();
        formData.append("image_input", request.response);

        self.getResult(formData);
      }
      request.open("GET", this.imageUpload);
      request.send();

    },
    clickUpload(){
      this.uploadingImage = !this.uploadingImage
      this.imageUpload = URL.createObjectURL(this.$refs.file.files[0]);

      let formData = new FormData();
      formData.append('image_input', this.$refs.file.files[0]);

      this.getResult(formData);
    },
    resetData(){
      this.uploadingImage = false
      this.results = null
      this.imageUpload = ""
    },
  }
}
</script>

<style>

/* .poc-container {
  margin: 173px auto;
  width: 1120px;
  height: 554px;
  display: flex;
  align-items: center;
  justify-content: space-between;
} */
/* 
.poc-container-left {
  width: 624px;
}

.poc-container-right {
  width: 448px;
} */

button.btn-poc {
  /* width: 448px; */
  height: 148px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 7px 12px rgb(79 79 79 / 12%);
  border-radius: 16px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

button.btn-poc {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #333333;
}

button.btn-poc:first-child {
  margin-bottom: 32px;
}

img.btn-poc-icon {
  width: 34px;
  margin-bottom: 20px;
}

.poc-right-content.upload-complate {
  display: flex;
  text-align: left;
  justify-content: center;
}

/* .poc-back {
  margin-top: 16px;
  text-align: left;
} */

.poc-back span {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #979797;
  display: flex;
  align-items: center;
}

img.poc-back-icon {
  width: 24px;
  margin-right: 8px;
}
</style>