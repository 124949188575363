<template>
  <div class="m-3 m-md-4">
    <div class="row mb-3 poc-result-row">{{ results.length }} Result</div>
    <div class="row mb-3" v-for="(car,index) in results" :key="index">
      <div class="d-flex p-0">
        <div class="flex-grow-1 m-1 m-sm-3" style="max-width:120px;">
          <canvas :ref="'canvas-'+index" class="img-fluid border bg-secondary" :style="{ 'border': '3px solid ' + car.color+ '!important' }" v-show="results"></canvas>
        </div>
        <div class="poc-row align-self-center">
          <div class="sub-row">
            <div class="type">ทะเบียนรถ</div>
            <div class="data">{{ textIfNUll(car.license) }}</div>
          </div>
          <div class="sub-row">
            <div class="type">จังหวัด</div>
            <div class="data">{{ textIfNUll(car.province) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PocResult',
  props: {
    imageUpload: {
      type: String,
      default: null
    },
    results: {
      type: Array,
      default: null
    }
  },
  methods: {
    textIfNUll(item) {
      return !item? '-' : item;
    }
  },
  mounted() {
    const self = this
    this.results.map((x,i)=>{
      const canvas = self.$refs["canvas-" + i]
      const context = canvas[0].getContext('2d')
      let img = new Image()
      img.src = self.imageUpload
      // Loading Pictures
      img.onload = function(){
        if(img.complete){
            var hRatio = context.canvas.width  / (x.xmax-x.xmin)    ;
            var vRatio =  context.canvas.height / (x.ymax-x.ymin)  ;
            var ratio  = Math.min ( hRatio, vRatio );
            var centerShift_x = ( context.canvas.width - (x.xmax-x.xmin)*ratio ) / 2;
            var centerShift_y = ( context.canvas.height - (x.ymax-x.ymin)*ratio ) / 2;  
            context.clearRect(0,0,context.canvas.width, context.canvas.height);

            context.drawImage(img, 
              x.xmin,x.ymin, 
              (x.xmax-x.xmin), (x.ymax-x.ymin),
              centerShift_x,centerShift_y,
              (x.xmax-x.xmin)*ratio, (x.ymax-x.ymin)*ratio
            );
        }
      }
    })
    
  }
}
</script>

<style>
.poc-result-row {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #01dab5;
}

.sub-row .type {
  width: 82px;
  font-style: normal;
  font-weight: 275;
  font-size: 20px;
  line-height: 30px;
  color: #2f3047;
  margin-right: 16px;
}

.sub-row .data {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #2f3047;
}

.poc-row {
  height: 78px;
}
.sub-row {
  display: flex;
}

/* .poc-row canvas {
  background-color: blue; 
  width: 100%;
  height: auto;
} */
</style>