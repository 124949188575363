<template>
  <div class="result-section">
    <div class="result-title">
      Result
    </div>
    <div class="result-document" v-if="!isRunExtraction">
      
    </div>
    <div class="result-document" v-else>
      ผลิต
    </div>
  </div>
</template>

<script>
export default {
  name: "HTRResult"
}
</script>

<style scoped>
  .result-section::v-deep {
    height: 635px;
    border: 2px solid #40E0D0;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 18px 32px;
    background-color: #FFFFFF;
  }

  .result-section::v-deep .result-title{
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 135%;
    color: #000000;
  }
  .result-section::v-deep .result-document{
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 250%;
    color: #000000;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 585px;
  }
</style>