export const _data = [
  {
    "file_path": "assets/demo/htr/1.png",
    "predictions": [
        {
          "id": 0,
          "recognize": "11 ซอยสุขุมวิท 40 แขวง พระโขนง",
          "xmax": 608,
          "xmin": 59,
          "ymax": 198,
          "ymin": 103
        },
        {
          "id": 1,
          "recognize": "กรุงเทพมหานคร 10111",
          "xmax": 495,
          "xmin": 40,
          "ymax": 297,
          "ymin": 209
        }
      ]
  },
  {
    "file_path": "assets/demo/htr/2.jpg",
    "predictions": [
        {
          "id": 0,
          "recognize": "รพ. กรุงเทพ เชียงใหม่",
          "xmax": 487,
          "xmin": 86,
          "ymax": 214,
          "ymin": 108
        },
        {
          "id": 1,
          "recognize": "88/8-9 ม.6 ต.หนอง",
          "xmax": 606,
          "xmin": 88,
          "ymax": 309,
          "ymin": 230
        },
        {
          "id": 2,
          "recognize": "อ.เมือง จ.เชียงใหม่ 50000",
          "xmax": 698,
          "xmin": 83,
          "ymax": 405,
          "ymin": 314
        }
      ]
  },
  {
    "file_path": "assets/demo/htr/3.png",
    "predictions": [
        {
          "id": 0,
          "recognize": "ซ.ลาดพร้าว ถ. ลาดพร้าว",
          "xmax": 523,
          "xmin": 107,
          "ymax": 196,
          "ymin": 127
        },
        {
          "id": 1,
          "recognize": "บางกะปิ 10240 กทม.",
          "xmax": 514,
          "xmin": 123,
          "ymax": 279,
          "ymin": 207
        }
      ]
  },
  {
    "file_path": "assets/demo/htr/4.png",
    "predictions": [
        {
          "id": 0,
          "recognize": "ซ.แจ้งวัฒนะ 10 ถ.แจ้งวัฒนะ",
          "xmax": 306,
          "xmin": 16,
          "ymax": 122,
          "ymin": 84
        },
        {
          "id": 1,
          "recognize": "แขวงทุ่งสองห้อง เขตหลักสี่ กรุงเทพฯ",
          "xmax": 387,
          "xmin": 19,
          "ymax": 172,
          "ymin": 127
        }
      ]
  },
  {
    "file_path": "assets/demo/htr/5.png",
    "predictions": [
        {
          "id": 0,
          "recognize": "ถนน รัชดาภิเษก",
          "xmax": 709,
          "xmin": 78,
          "ymax": 332,
          "ymin": 174
        }
      ]
  },
  {
    "file_path": "assets/demo/htr/6.png",
    "predictions": [
        {
          "id": 0,
          "recognize": "123 ชั้น 2 อาคาร กรม การกงสุล",
          "xmax": 336,
          "xmin": 44,
          "ymax": 106,
          "ymin": 69
        },
        {
          "id": 1,
          "recognize": "แขวงทุ่งสองห้อง เขตหลักสี่",
          "xmax": 321,
          "xmin": 47,
          "ymax": 147,
          "ymin": 110
        },
        {
          "id": 2,
          "recognize": "จ.กรุงเทพมหานคร 10210",
          "xmax": 346,
          "xmin": 54,
          "ymax": 189,
          "ymin": 151
        }
      ]
  },
  {
    "file_path": "assets/demo/htr/7.png",
    "predictions": [
        {
          "id": 0,
          "recognize": "ถนน สาม เสน",
          "xmax": 459,
          "xmin": 179,
          "ymax": 142,
          "ymin": 70
        },
        {
          "id": 1,
          "recognize": "เขต ดุสิต",
          "xmax": 391,
          "xmin": 184,
          "ymax": 263,
          "ymin": 169
        },
        {
          "id": 2,
          "recognize": "กรุงเทพฯ",
          "xmax": 395,
          "xmin": 184,
          "ymax": 353,
          "ymin": 270
        }
      ]
  },
  {
    "file_path": "assets/demo/htr/8.png",
    "predictions": [
        {
          "id": 0,
          "recognize": "จังหวัด ประจวบคีรีขันธ์",
          "xmax": 599,
          "xmin": 45,
          "ymax": 251,
          "ymin": 131
        }
      ]
  },
  {
    "file_path": "assets/demo/htr/9.png",
    "predictions": [
        {
          "id": 0,
          "recognize": "ซ. รามคำแหง 43/1",
          "xmax": 534,
          "xmin": 189,
          "ymax": 139,
          "ymin": 78
        },
        {
          "id": 1,
          "recognize": "แขวงพลับพลา",
          "xmax": 385,
          "xmin": 152,
          "ymax": 201,
          "ymin": 156
        },
        {
          "id": 2,
          "recognize": "เขตวังทองหลาง",
          "xmax": 430,
          "xmin": 155,
          "ymax": 259,
          "ymin": 208
        },
        {
          "id": 3,
          "recognize": "กทม 10310",
          "xmax": 404,
          "xmin": 168,
          "ymax": 329,
          "ymin": 282
        }
      ]
  },
]